import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

const Login = () =>
  import(/* webpackChunkName: "chlogin" */ "../views/Login.vue");
const MapsTypes = () =>
  import(/* webpackChunkName: "challTypes" */ "../views/home/MapsTypes.vue");

const PrivateSectorMap = () =>
  import(/* webpackChunkName: "challstores" */ "../views/private_sector/PrivateSectorMap.vue");
const PrivateSectorProjects = () =>
  import(/* webpackChunkName: "challTypes" */ "../views/private_sector/PrivateSectorProjects.vue");

const PublicSectorMap = () =>
  import(/* webpackChunkName: "challstores" */ "../views/public_sector/PublicSectorMap.vue");
const PublicSectorProjects = () =>
  import(/* webpackChunkName: "challTypes" */ "../views/public_sector/PublicSectorProjects.vue");

const MixedSectorMap = () =>
  import(/* webpackChunkName: "challstores" */ "../views/mixed_sector/MixedSectorMap.vue");
const MixedSectorProjects = () =>
  import(/* webpackChunkName: "challTypes" */ "../views/mixed_sector/MixedSectorProjects.vue");

const IndustrialCitiesMap = () =>
  import(/* webpackChunkName: "challstores" */ "../views/industrial_cities/IndustrialCitiesMap.vue");
const IndustrialCities = () =>
  import(/* webpackChunkName: "challTypes" */ "../views/industrial_cities/IndustrialCities.vue");

const Admins = () =>
  import(/* webpackChunkName: "challTypes" */ "../views/users/Admins.vue");
const Users = () =>
  import(/* webpackChunkName: "challTypes" */ "../views/users/Users.vue");


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"]) {
        return next({
          name: "MapsTypes",
        });
      }
      next();
    },
  },
  {
    path: '/Admins',
    name: 'Admins',
    meta:{
      ar_name:'مشرفين النظام'
    },
    component: Admins,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }
      next();
    },
  },
  {
    path: '/Users',
    name: 'Users',
    meta:{
      ar_name:'مستخدمين النظام'
    },
    component: Users,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }
      next();
    },
  },
  {
    path: '/MapsTypes',
    name: 'MapsTypes',
    meta:{
      ar_name:'انواع الخرائط'
    },
    component: MapsTypes,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }
      next();
    },
  },
  {
    path: '/PrivateSectorMap',
    name: 'PrivateSectorMap',
    meta:{
      ar_name:'خارطة مشاريع القطاع الخاص',
    },
    component: PrivateSectorMap,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }
      next();
    },
  },
  {
    path: '/PrivateSectorProjects',
    name: 'PrivateSectorProjects',
    meta:{
      ar_name:'مشاريع القطاع الخاص'
    },
    component: PrivateSectorProjects,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }
      next();
    },
  },

  {
    path: '/PublicSectorMap',
    name: 'PublicSectorMap',
    meta:{
      ar_name:'خارطة مشاريع القطاع العام'
    },
    component: PublicSectorMap,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }
      next();
    },
  },
  {
    path: '/PublicSectorProjects',
    name: 'PublicSectorProjects',
    meta:{
      ar_name:'مشاريع القطاع العام'
    },
    component: PublicSectorProjects,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }
      next();
    },
  },

  {
    path: '/MixedSectorMap',
    name: 'MixedSectorMap',
    meta:{
      ar_name:'خارطة مشاريع القطاع المختلط'
    },
    component: MixedSectorMap,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }
      next();
    },
  },
  {
    path: '/MixedSectorProjects',
    name: 'MixedSectorProjects',
    meta:{
      ar_name:'مشاريع القطاع المختلط'
    },
    component: MixedSectorProjects,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }
      next();
    },
  },

  {
    path: '/IndustrialCitiesMap',
    name: 'IndustrialCitiesMap',
    meta:{
      ar_name:'خارطة المدن الصناعية'
    },
    component: IndustrialCitiesMap,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }
      next();
    },
  },
  {
    path: '/IndustrialCities',
    name: 'IndustrialCities',
    meta:{
      ar_name:'المدن الصناعية'
    },
    component: IndustrialCities,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "Login",
        });
      }
      next();
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
