import Vue from 'vue'
import Vuetify from 'vuetify/lib'


Vue.use(Vuetify)

export default new Vuetify({
  rtl: true,
  theme: {
    themes: {
      light: {
        primary: '#2A2E35', // #E53935
        secondary: '#454E56', // #FFCDD2
        accent: '#5D86FF', // #3F51B5
        white: '#FFFFFF', // #3F51B5
        color1:'#334257',
        color2:'#476072',
        color3:'#548CA8',
        color4:'#6E85B2',
      },
    },
  },
})
