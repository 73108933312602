<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      right
      temporary
      dark
      class="primary"
    >
      <v-list class="pa-0">
        <v-list-group v-for="item in navItems" :key="item.title" color="accent">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            link
            :to="child.path"
          >
            <v-list-item-content>
              <v-list-item-title
                class="subtitile-1"
                v-text="child.title"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-list class="pa-0" v-if="this.user.type != `User`">
        <v-list-item
          link
          :to="authItems[0].path"
          color="accent"
          v-if="this.user.type == `SuperAdmin`"
        >
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">{{
              authItems[0].title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="authItems[1].path" color="accent">
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">{{
              authItems[1].title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark class="px-5">
      <v-app-bar-nav-icon
        v-if="
          this.$route.name != `MapsTypes` &&
          this.$route.name != `UnderDevelopment`
        "
        @click.stop="drawer = !drawer"
        class="mr-4"
      />
      <p
        class="
          pa-0
          ma-0
          font-weight-bold
          text-xl-h5 text-lg-h6 text-xl-h5 text-md-h6
        "
      >
          وزارة الصناعة والمعادن المديرية العامة للتنمية الصناعية
      </p>
      <p
        class="
          pa-0
          ma-0
          mx-1
          font-weight-bold
          text-xl-h5 text-lg-h6 text-xl-h5 text-md-h6
        "
      >
         / {{ this.$route.meta.ar_name }}
      </p>
      <v-spacer></v-spacer>
      <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon large>mdi-account-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list nav>
          <v-list-item link @click="Home">
            <v-list-item-icon>
              <v-icon>mdi-home-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>الواجهة الرئيسية</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="Logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>تسجيل الخروج</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}
.expand {
  height: 80px !important;
  padding-top: 10px;
}
</style>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    drawer: null,
    isXs: false,
    navItems: [
      {
        title: "القطاع الخاص",
        items: [
          { title: "خارطة مشاريع القطاع الخاص", path: "/PrivateSectorMap" },
          { title: "مشاريع القطاع الخاص", path: "/PrivateSectorProjects" },
        ],
      },
      {
        title: "القطاع العام",
        items: [
          { title: "خارطة مشاريع القطاع العام", path: "/PublicSectorMap" },
          { title: "مشاريع القطاع العام", path: "/PublicSectorProjects" },
        ],
      },
      {
        title: "القطاع المختلط",
        items: [
          { title: "خارطة مشاريع القطاع المختلط", path: "/MixedSectorMap" },
          { title: "مشاريع القطاع المختلط", path: "/MixedSectorProjects" },
        ],
      },
      {
        title: "المدن الصناعية",
        items: [
          { title: "خارطة المدن الصناعية", path: "/IndustrialCitiesMap" },
          { title: "المدن الصناعية", path: "/IndustrialCities" },
        ],
      },
    ],
    authItems: [
      { title: "مشرفين النظام", path: "/Admins" },
      { title: "مستخدمين النظام", path: "/Users" },
    ],
  }),
  props: {
    flat: Boolean,
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 950;
    },
    ...mapActions({
      LogoutAction: "auth/Logout",
    }),
    Logout() {
      this.LogoutAction().then(() => {
        this.$router.replace({ name: "Login" });
      });
    },
    Home() {
      this.$router.replace({ name: "MapsTypes" });
    },

    indexWhere(array, conditionFn) {
      const item = array.find(conditionFn);
      return array.indexOf(item);
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
