<template>
  <v-app>
    <navbar v-if="this.authenticated" :flat="flat" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import navbar from "./components/navbar";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    navbar,
  },
  data: () => ({
    flat: null,
  }),
  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.flat = true;
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
    }),
  },
};
</script>
<style>

#app {
  background: url("~@/assets/background.svg") no-repeat center center fixed !important;
  background-size: cover;
}

</style>
