import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import JsonExcel from "vue-json-excel";


Vue.config.productionTip = false
require('@/store/subscriber')
axios.defaults.baseURL = "https://backend.gdid-platform.com/api/"
store.dispatch('auth/attempt',localStorage.getItem('token')).then(()=>{
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
  })
  Vue.component("downloadExcel", JsonExcel);
